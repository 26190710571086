import addPlanReducer from '@apps/shared/src/addPlan/reducer';
import userReducer from '@apps/shared/src/auth/userReducer';
import ResetSessionTimeout from '@apps/shared/src/middleware/ResetSessionTimeout';
import adminReducer from '@apps/shared/src/components/AdminUserList/adminReducer';
import planSelectReducer from '@apps/shared/src/planSelect/reducer';
import providerSearchReducer from '@apps/shared/src/providerSearch/reducer';
import registerReducer from '@apps/shared/src/register/reducer';
import caseReducer from '@apps/shared/src/caseSelect/reducer';
import addCaseReducer from '@apps/shared/src/addCase/reducer';
import {
  getDependentMembersReducer,
  addDependentMemberReducer,
} from '@apps/shared/src/dependentMember/reducer';
import getMemberReducer from '@apps/shared/src/getMember/reducer';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import analysisReducer from './analysis/analysisReducer';
import benchmarkReducer from './benchmarks/benchmarkReducer';
import reportReducer from './reports/reportReducer';
import snackbarReducer from './shared/snackbarReducer';

const reducers = combineReducers({
  benchmarks: benchmarkReducer,
  snackbars: snackbarReducer,
  analysis: analysisReducer,
  user: userReducer,
  reports: reportReducer,
  planSelect: planSelectReducer,
  register: registerReducer,
  addPlan: addPlanReducer,
  admin: adminReducer,
  providerSearch: providerSearchReducer,
  caseSelect: caseReducer,
  addCase: addCaseReducer,
  getMember: getMemberReducer,
  getDependentMember: getDependentMembersReducer,
  addDependentMember: addDependentMemberReducer,
});

const middleware = applyMiddleware(promise, thunk, ResetSessionTimeout);
const store = createStore(reducers, composeWithDevTools(middleware));

export type RootState = ReturnType<typeof store.getState>;
export default store;
